.dashboard-wrapper {
  padding: 2rem;
  max-width: 1920px;
  max-height: 1200px;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1rem;
  /* margin-left: 4rem; */
  height: 100%;
  width: 100%;
}

.dashboard-main-wrapper {
  min-width: 0;
  height: 100%;
  overflow-y: overlay; /* Later change back to overlay*/
  display: flex;
  flex-direction: column;
}

.dashboard-invite-summary-wrapper {
  display: flex;
  gap: 2rem;
}

.dashboard-grid {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1 1 auto;
  padding-top: 4rem;
  overflow-y: overlay;
}

.ring-loader {
  height: 100vh;
}
