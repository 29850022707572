.back-button-details-container {
  grid-area: client-details;
  /* padding-bottom: 1.5rem; */
  display: flex;
  flex-direction: column;

  height: calc(100%);
  /* overflow-y: overlay; */
  overflow-y: overlay;
  overflow-x: hidden;
  flex-direction: column;
}

.client-details-all-list {
  margin-bottom: 1.5rem;
  overflow-y: scroll;
  overflow-x: hidden;
  flex: 1 1;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.client-chart-heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2.5rem;
  gap: 1.25rem;
}

.back-arrow {
  cursor: pointer;
}

.name-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.client-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  color: #282860;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
}

.client-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.client-image {
  border-radius: 1rem;
  object-fit: contain;
  width: calc(100%);
  height: 15rem;
  margin-bottom: 1rem;
}

.session-button {
  color: #282860;
  background-color: #f1d07c;
  border-radius: 0.4rem;
  padding: 0.7rem 1.3rem;
  font-family: "poppins";
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: inherit;
  border: none;
}

.session-button:disabled {
  cursor: not-allowed;
  color: #61618b;
  background-color: #f0e3c3;
}

.client-details-list {
  margin-top: 1.5rem;
}

/* .client-details-key-list{
    text-align: left;
    margin-right: 24px;
} */

.list-item-container {
  width: 100%;
  margin: 1rem 0rem;
}

.client-details-list-item-key {
  font-family: "poppins";
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  text-align: left;
  color: #282860;
  opacity: 0.7;
  display: inline-block;
  width: 35%;
}

.client-details-list-item-value {
  padding-left: 1rem;
  font-family: "poppins";
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  text-align: left;
  color: #282860;
  display: inline-block;
  width: 65%;
}

.client-details-subheading-container {
  display: flex;
  align-items: center;
  margin-right: 1.25rem;
}

.client-details-subheading {
  font-family: "poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #282860;
  margin-left: 1rem;
}

.client-concerns-container,
.client-notes-container-header {
  display: flex;
  margin-bottom: 1rem;
}

.client-concerns-modal {
  padding: 2.5rem;
}

.concerns-section-header {
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
}

.concerns-section-heading {
  font-family: "poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  /* or 21px */
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;

  opacity: 0.7;
}

.concerns-modal-save-button {
  margin-top: 2rem;
  background: #5f6ce1;
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  display: inline-block;
  font-size: 0.9rem;
  text-align: center;
  color: #ffffff;
}

.selected-concerns-container {
  display: inline;
}

.selected-concern-tile {
  background: rgba(171, 178, 242, 0.5);
  border: 1px solid #5f6ce1;
  border-radius: 6px;
  margin-right: 1rem;
  width: fit-content;
  height: fit-content;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.selected-concern-tile-text {
  display: inline-block;
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  text-align: center;
  color: #5f6ce1;
  padding: 0.5rem 1rem;
}

.client-notes-container-body {
  max-height: 30rem;
  overflow-y: overlay;
}

.note-input-container {
  border-radius: 1rem;
  border: 1px solid rgba(40, 40, 96, 0.15);
  background: #fff;
  box-shadow: 0rem 0.5rem 1rem 0rem rgba(41, 40, 45, 0.02);
  margin-bottom: 1rem;
  padding: 1rem;
}

.notes-textarea {
  color: #282860;
  font-size: 0.9rem;
  font-family: "Poppins";
  font-weight: 500;
  height: 3.75vw;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  width: 100%;
  overflow-y: overlay;
}

.date-delete-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.note-date-container {
  color: #282860;
  font-size: 0.75rem;
  font-family: "Poppins";
  font-weight: 500;
  opacity: 0.5;
  margin-top: 1rem;
  text-align: center;
}

.realtime-session-button-start {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 6px 0px;
  background-color: #d32f2f;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  font-family: "Poppins";
}

.realtime-session-button-end {
  background-color: #ae253d;
}

.realtime-session-button-start:hover {
  background-color: #b82727;
  cursor: pointer;
  user-select: none;
}

.realtime-button-mic-icon {
  height: 70%;
}

.invite-client-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: rgba(46, 183, 88, 1);
  color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  font-family: "Poppins";
  padding: 10px 0px;
}

.invite-client-button:hover {
  background-color: #5460cd;
}

.invite-client-already-sent-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  background: rgba(46, 183, 88, 0.5);
  color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  font-family: "Poppins";
  padding: 10px 0px;
}
