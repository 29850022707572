.client-screen-wrapper {
  height: 100%;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;

  max-width: 1920px;
  max-height: 1200px;
  /* margin-left: 4rem; */
}
