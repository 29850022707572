*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  
}
::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  
}
.visible {
    visibility: visible
}
.absolute {
    position: absolute
}
.relative {
    position: relative
}
.inset-0 {
    inset: 0px
}
.inset-2 {
    inset: 0.5rem
}
.inset-\[-8px\] {
    inset: -8px
}
.-right-1 {
    right: -0.25rem
}
.-top-1 {
    top: -0.25rem
}
.bottom-0 {
    bottom: 0px
}
.left-0 {
    left: 0px
}
.left-3 {
    left: 0.75rem
}
.right-0 {
    right: 0px
}
.top-0 {
    top: 0px
}
.top-1\/2 {
    top: 50%
}
.-bottom-2 {
    bottom: -0.5rem
}
.-right-2 {
    right: -0.5rem
}
.-right-0\.5 {
    right: -0.125rem
}
.-top-0\.5 {
    top: -0.125rem
}
.-z-10 {
    z-index: -10
}
.col-span-3 {
    grid-column: span 3 / span 3
}
.col-span-9 {
    grid-column: span 9 / span 9
}
.m-0 {
    margin: 0px
}
.mx-auto {
    margin-left: auto;
    margin-right: auto
}
.my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
}
.-mb-12 {
    margin-bottom: -3rem
}
.-mb-px {
    margin-bottom: -1px
}
.-ml-12 {
    margin-left: -3rem
}
.-ml-16 {
    margin-left: -4rem
}
.-mr-12 {
    margin-right: -3rem
}
.-mr-16 {
    margin-right: -4rem
}
.-mt-16 {
    margin-top: -4rem
}
.mb-1 {
    margin-bottom: 0.25rem
}
.mb-2 {
    margin-bottom: 0.5rem
}
.mb-4 {
    margin-bottom: 1rem
}
.mb-6 {
    margin-bottom: 1.5rem
}
.mb-8 {
    margin-bottom: 2rem
}
.ml-4 {
    margin-left: 1rem
}
.mr-4 {
    margin-right: 1rem
}
.mt-1 {
    margin-top: 0.25rem
}
.mt-2 {
    margin-top: 0.5rem
}
.mt-4 {
    margin-top: 1rem
}
.mt-6 {
    margin-top: 1.5rem
}
.mr-2 {
    margin-right: 0.5rem
}
.ml-2 {
    margin-left: 0.5rem
}
.mr-1 {
    margin-right: 0.25rem
}
.block {
    display: block
}
.inline-block {
    display: inline-block
}
.flex {
    display: flex
}
.inline-flex {
    display: inline-flex
}
.table {
    display: table
}
.grid {
    display: grid
}
.contents {
    display: contents
}
.hidden {
    display: none
}
.h-1\.5 {
    height: 0.375rem
}
.h-12 {
    height: 3rem
}
.h-16 {
    height: 4rem
}
.h-24 {
    height: 6rem
}
.h-3 {
    height: 0.75rem
}
.h-32 {
    height: 8rem
}
.h-4 {
    height: 1rem
}
.h-48 {
    height: 12rem
}
.h-5 {
    height: 1.25rem
}
.h-6 {
    height: 1.5rem
}
.h-8 {
    height: 2rem
}
.h-full {
    height: 100%
}
.h-9 {
    height: 2.25rem
}
.h-64 {
    height: 16rem
}
.h-96 {
    height: 24rem
}
.h-px {
    height: 1px
}
.h-2 {
    height: 0.5rem
}
.h-3\.5 {
    height: 0.875rem
}
.h-7 {
    height: 1.75rem
}
.h-14 {
    height: 3.5rem
}
.h-\[56px\] {
    height: 56px
}
.min-h-screen {
    min-height: 100vh
}
.min-h-\[60px\] {
    min-height: 60px
}
.w-12 {
    width: 3rem
}
.w-16 {
    width: 4rem
}
.w-24 {
    width: 6rem
}
.w-3 {
    width: 0.75rem
}
.w-32 {
    width: 8rem
}
.w-4 {
    width: 1rem
}
.w-48 {
    width: 12rem
}
.w-5 {
    width: 1.25rem
}
.w-6 {
    width: 1.5rem
}
.w-8 {
    width: 2rem
}
.w-full {
    width: 100%
}
.w-11 {
    width: 2.75rem
}
.w-1\/2 {
    width: 50%
}
.w-1\/3 {
    width: 33.333333%
}
.w-64 {
    width: 16rem
}
.w-2 {
    width: 0.5rem
}
.w-3\.5 {
    width: 0.875rem
}
.w-7 {
    width: 1.75rem
}
.w-14 {
    width: 3.5rem
}
.min-w-0 {
    min-width: 0px
}
.max-w-7xl {
    max-width: 80rem
}
.max-w-md {
    max-width: 28rem
}
.max-w-\[1440px\] {
    max-width: 1440px
}
.flex-1 {
    flex: 1 1 0%
}
.flex-shrink-0 {
    flex-shrink: 0
}
.flex-grow {
    flex-grow: 1
}
.-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.translate-x-1 {
    --tw-translate-x: 0.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.translate-x-6 {
    --tw-translate-x: 1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
@keyframes ping {
    75%, 100% {
        transform: scale(2);
        opacity: 0
    }
}
.animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
}
@keyframes pulse {
    50% {
        opacity: .5
    }
}
.animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
}
@keyframes spin {
    to {
        transform: rotate(360deg)
    }
}
.animate-spin {
    animation: spin 1s linear infinite
}
.cursor-pointer {
    cursor: pointer
}
.list-none {
    list-style-type: none
}
.appearance-none {
    appearance: none
}
.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
}
.grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
}
.grid-cols-\[40px\2c 240px\2c 1fr\2c 180px\2c 80px\] {
    grid-template-columns: 40px 240px 1fr 180px 80px
}
.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
}
.flex-row {
    flex-direction: row
}
.flex-col {
    flex-direction: column
}
.items-start {
    align-items: flex-start
}
.items-center {
    align-items: center
}
.items-baseline {
    align-items: baseline
}
.justify-end {
    justify-content: flex-end
}
.justify-center {
    justify-content: center
}
.justify-between {
    justify-content: space-between
}
.gap-1 {
    gap: 0.25rem
}
.gap-2 {
    gap: 0.5rem
}
.gap-3 {
    gap: 0.75rem
}
.gap-4 {
    gap: 1rem
}
.gap-6 {
    gap: 1.5rem
}
.gap-8 {
    gap: 2rem
}
.gap-1\.5 {
    gap: 0.375rem
}
.space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))
}
.space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse))
}
.space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse))
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
}
.space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse))
}
.space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse))
}
.space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse))
}
.space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse))
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse))
}
.divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
}
.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-divide-opacity, 1))
}
.overflow-hidden {
    overflow: hidden
}
.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}
.whitespace-nowrap {
    white-space: nowrap
}
.whitespace-pre-wrap {
    white-space: pre-wrap
}
.break-words {
    overflow-wrap: break-word
}
.rounded {
    border-radius: 0.25rem
}
.rounded-full {
    border-radius: 9999px
}
.rounded-lg {
    border-radius: 0.5rem
}
.rounded-xl {
    border-radius: 0.75rem
}
.rounded-md {
    border-radius: 0.375rem
}
.rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem
}
.border {
    border-width: 1px
}
.border-4 {
    border-width: 4px
}
.border-0 {
    border-width: 0px
}
.border-2 {
    border-width: 2px
}
.border-y {
    border-top-width: 1px;
    border-bottom-width: 1px
}
.border-b {
    border-bottom-width: 1px
}
.border-b-2 {
    border-bottom-width: 2px
}
.border-t {
    border-top-width: 1px
}
.border-b-\[3px\] {
    border-bottom-width: 3px
}
.border-solid {
    border-style: solid
}
.border-none {
    border-style: none
}
.border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgb(191 219 254 / var(--tw-border-opacity, 1))
}
.border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgb(59 130 246 / var(--tw-border-opacity, 1))
}
.border-cyan-200 {
    --tw-border-opacity: 1;
    border-color: rgb(165 243 252 / var(--tw-border-opacity, 1))
}
.border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-border-opacity, 1))
}
.border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity, 1))
}
.border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgb(199 210 254 / var(--tw-border-opacity, 1))
}
.border-sky-200 {
    --tw-border-opacity: 1;
    border-color: rgb(186 230 253 / var(--tw-border-opacity, 1))
}
.border-teal-200 {
    --tw-border-opacity: 1;
    border-color: rgb(153 246 228 / var(--tw-border-opacity, 1))
}
.border-transparent {
    border-color: transparent
}
.border-violet-100 {
    --tw-border-opacity: 1;
    border-color: rgb(237 233 254 / var(--tw-border-opacity, 1))
}
.border-amber-200 {
    --tw-border-opacity: 1;
    border-color: rgb(253 230 138 / var(--tw-border-opacity, 1))
}
.border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgb(224 231 255 / var(--tw-border-opacity, 1))
}
.border-amber-100 {
    --tw-border-opacity: 1;
    border-color: rgb(254 243 199 / var(--tw-border-opacity, 1))
}
.border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgb(220 252 231 / var(--tw-border-opacity, 1))
}
.border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity, 1))
}
.border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgb(79 70 229 / var(--tw-border-opacity, 1))
}
.border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgb(156 163 175 / var(--tw-border-opacity, 1))
}
.bg-\[\#F8F9FE\] {
    --tw-bg-opacity: 1;
    background-color: rgb(248 249 254 / var(--tw-bg-opacity, 1))
}
.bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(219 234 254 / var(--tw-bg-opacity, 1))
}
.bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(239 246 255 / var(--tw-bg-opacity, 1))
}
.bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity, 1))
}
.bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1))
}
.bg-cyan-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(6 182 212 / var(--tw-bg-opacity, 1))
}
.bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1))
}
.bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity, 1))
}
.bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 242 242 / var(--tw-bg-opacity, 1))
}
.bg-sky-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(14 165 233 / var(--tw-bg-opacity, 1))
}
.bg-teal-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(20 184 166 / var(--tw-bg-opacity, 1))
}
.bg-violet-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 243 255 / var(--tw-bg-opacity, 1))
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1))
}
.bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1))
}
.bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(79 70 229 / var(--tw-bg-opacity, 1))
}
.bg-transparent {
    background-color: transparent
}
.bg-amber-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 158 11 / var(--tw-bg-opacity, 1))
}
.bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity, 1))
}
.bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(238 242 255 / var(--tw-bg-opacity, 1))
}
.bg-rose-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(244 63 94 / var(--tw-bg-opacity, 1))
}
.bg-white\/10 {
    background-color: rgb(255 255 255 / 0.1)
}
.bg-blue-500\/80 {
    background-color: rgb(59 130 246 / 0.8)
}
.bg-amber-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 243 199 / var(--tw-bg-opacity, 1))
}
.bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(220 252 231 / var(--tw-bg-opacity, 1))
}
.bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(224 231 255 / var(--tw-bg-opacity, 1))
}
.bg-white\/80 {
    background-color: rgb(255 255 255 / 0.8)
}
.bg-indigo-50\/50 {
    background-color: rgb(238 242 255 / 0.5)
}
.bg-indigo-100\/50 {
    background-color: rgb(224 231 255 / 0.5)
}
.bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(199 210 254 / var(--tw-bg-opacity, 1))
}
.bg-indigo-100\/70 {
    background-color: rgb(224 231 255 / 0.7)
}
.bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
}
.bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops))
}
.bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops))
}
.bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops))
}
.bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops))
}
.bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops))
}
.bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops))
}
.from-blue-50 {
    --tw-gradient-from: #eff6ff var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-blue-500\/10 {
    --tw-gradient-from: rgb(59 130 246 / 0.1) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-violet-100 {
    --tw-gradient-from: #ede9fe var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-violet-500 {
    --tw-gradient-from: #8b5cf6 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-indigo-600 {
    --tw-gradient-from: #4f46e5 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-slate-50 {
    --tw-gradient-from: #f8fafc var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-indigo-100\/20 {
    --tw-gradient-from: rgb(224 231 255 / 0.2) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-indigo-50 {
    --tw-gradient-from: #eef2ff var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-transparent {
    --tw-gradient-from: transparent var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-white\/10 {
    --tw-gradient-from: rgb(255 255 255 / 0.1) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.via-sky-500\/5 {
    --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), rgb(14 165 233 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to)
}
.via-white {
    --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #fff var(--tw-gradient-via-position), var(--tw-gradient-to)
}
.via-amber-100\/20 {
    --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), rgb(254 243 199 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to)
}
.via-white\/50 {
    --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to)
}
.to-purple-600 {
    --tw-gradient-to: #9333ea var(--tw-gradient-to-position)
}
.to-transparent {
    --tw-gradient-to: transparent var(--tw-gradient-to-position)
}
.to-indigo-700 {
    --tw-gradient-to: #4338ca var(--tw-gradient-to-position)
}
.to-slate-50 {
    --tw-gradient-to: #f8fafc var(--tw-gradient-to-position)
}
.to-white {
    --tw-gradient-to: #fff var(--tw-gradient-to-position)
}
.p-1 {
    padding: 0.25rem
}
.p-1\.5 {
    padding: 0.375rem
}
.p-2 {
    padding: 0.5rem
}
.p-3 {
    padding: 0.75rem
}
.p-6 {
    padding: 1.5rem
}
.p-8 {
    padding: 2rem
}
.p-4 {
    padding: 1rem
}
.p-0\.5 {
    padding: 0.125rem
}
.p-5 {
    padding: 1.25rem
}
.p-0 {
    padding: 0px
}
.px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem
}
.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
}
.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem
}
.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}
.py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem
}
.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
}
.py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem
}
.py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem
}
.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
}
.py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
}
.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
}
.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
}
.py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem
}
.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
}
.px-8 {
    padding-left: 2rem;
    padding-right: 2rem
}
.px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem
}
.pb-3 {
    padding-bottom: 0.75rem
}
.pl-10 {
    padding-left: 2.5rem
}
.pl-6 {
    padding-left: 1.5rem
}
.pr-4 {
    padding-right: 1rem
}
.pt-0 {
    padding-top: 0px
}
.pt-4 {
    padding-top: 1rem
}
.pb-1 {
    padding-bottom: 0.25rem
}
.pb-2 {
    padding-bottom: 0.5rem
}
.pt-1 {
    padding-top: 0.25rem
}
.text-left {
    text-align: left
}
.text-center {
    text-align: center
}
.font-sans {
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}
.font-serif {
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif
}
.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem
}
.text-6xl {
    font-size: 3.75rem;
    line-height: 1
}
.text-base {
    font-size: 1rem;
    line-height: 1.5rem
}
.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem
}
.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem
}
.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem
}
.text-xs {
    font-size: 0.75rem;
    line-height: 1rem
}
.font-bold {
    font-weight: 700
}
.font-medium {
    font-weight: 500
}
.font-semibold {
    font-weight: 600
}
.capitalize {
    text-transform: capitalize
}
.italic {
    font-style: italic
}
.leading-relaxed {
    line-height: 1.625
}
.tracking-tight {
    letter-spacing: -0.025em
}
.text-amber-500 {
    --tw-text-opacity: 1;
    color: rgb(245 158 11 / var(--tw-text-opacity, 1))
}
.text-blue-500 {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity, 1))
}
.text-blue-600 {
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity, 1))
}
.text-cyan-600 {
    --tw-text-opacity: 1;
    color: rgb(8 145 178 / var(--tw-text-opacity, 1))
}
.text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity, 1))
}
.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity, 1))
}
.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity, 1))
}
.text-gray-800 {
    --tw-text-opacity: 1;
    color: rgb(31 41 55 / var(--tw-text-opacity, 1))
}
.text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity, 1))
}
.text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity, 1))
}
.text-red-500 {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity, 1))
}
.text-red-600 {
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity, 1))
}
.text-sky-600 {
    --tw-text-opacity: 1;
    color: rgb(2 132 199 / var(--tw-text-opacity, 1))
}
.text-teal-600 {
    --tw-text-opacity: 1;
    color: rgb(13 148 136 / var(--tw-text-opacity, 1))
}
.text-violet-600 {
    --tw-text-opacity: 1;
    color: rgb(124 58 237 / var(--tw-text-opacity, 1))
}
.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1))
}
.text-white\/80 {
    color: rgb(255 255 255 / 0.8)
}
.text-amber-600 {
    --tw-text-opacity: 1;
    color: rgb(217 119 6 / var(--tw-text-opacity, 1))
}
.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity, 1))
}
.text-green-500 {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity, 1))
}
.text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgb(224 231 255 / var(--tw-text-opacity, 1))
}
.text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgb(49 46 129 / var(--tw-text-opacity, 1))
}
.text-rose-500 {
    --tw-text-opacity: 1;
    color: rgb(244 63 94 / var(--tw-text-opacity, 1))
}
.text-amber-700 {
    --tw-text-opacity: 1;
    color: rgb(180 83 9 / var(--tw-text-opacity, 1))
}
.text-green-700 {
    --tw-text-opacity: 1;
    color: rgb(21 128 61 / var(--tw-text-opacity, 1))
}
.text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgb(55 48 163 / var(--tw-text-opacity, 1))
}
.underline {
    text-decoration-line: underline
}
.opacity-10 {
    opacity: 0.1
}
.opacity-50 {
    opacity: 0.5
}
.opacity-75 {
    opacity: 0.75
}
.opacity-0 {
    opacity: 0
}
.shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.shadow {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.outline {
    outline-style: solid
}
.ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}
.ring-opacity-20 {
    --tw-ring-opacity: 0.2
}
.blur {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}
.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}
.transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}
.transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}
.transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}
.transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}
.duration-200 {
    transition-duration: 200ms
}
@keyframes pulse {
    50% {
        opacity: .5
    }
}
.hover\:animate-pulse:hover {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
}
.hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity, 1))
}
.hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(239 246 255 / var(--tw-bg-opacity, 1))
}
.hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(29 78 216 / var(--tw-bg-opacity, 1))
}
.hover\:bg-cyan-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(236 254 255 / var(--tw-bg-opacity, 1))
}
.hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(238 242 255 / var(--tw-bg-opacity, 1))
}
.hover\:bg-sky-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(240 249 255 / var(--tw-bg-opacity, 1))
}
.hover\:bg-teal-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 250 / var(--tw-bg-opacity, 1))
}
.hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1))
}
.hover\:bg-amber-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 251 235 / var(--tw-bg-opacity, 1))
}
.hover\:bg-amber-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(217 119 6 / var(--tw-bg-opacity, 1))
}
.hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(67 56 202 / var(--tw-bg-opacity, 1))
}
.hover\:bg-rose-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(225 29 72 / var(--tw-bg-opacity, 1))
}
.hover\:bg-white\/20:hover {
    background-color: rgb(255 255 255 / 0.2)
}
.hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1))
}
.hover\:bg-blue-600\/80:hover {
    background-color: rgb(37 99 235 / 0.8)
}
.hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgb(29 78 216 / var(--tw-text-opacity, 1))
}
.hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity, 1))
}
.hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity, 1))
}
.hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity, 1))
}
.hover\:no-underline:hover {
    text-decoration-line: none
}
.hover\:opacity-100:hover {
    opacity: 1
}
.hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.focus\:border-transparent:focus {
    border-color: transparent
}
.focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgb(165 180 252 / var(--tw-border-opacity, 1))
}
.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
}
.focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}
.focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}
.focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity, 1))
}
.focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity, 1))
}
.focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px
}
.disabled\:opacity-50:disabled {
    opacity: 0.5
}
.group:hover .group-hover\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.group:hover .group-hover\:opacity-100 {
    opacity: 1
}
@media (min-width: 640px) {
    .sm\:flex-row {
        flex-direction: row
    }
    .sm\:items-center {
        align-items: center
    }
    .sm\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }
}
@media (min-width: 768px) {
    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }
}
@media (min-width: 1024px) {
    .lg\:px-8 {
        padding-left: 2rem;
        padding-right: 2rem
    }
}