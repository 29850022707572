.text-input-field.session-with-field {
  width: 100%;
}

.text-input-field {
  margin-bottom: 30px;
  margin-top: 10px;
}

.modal-body.add-session-modal-body {
  padding-top: 24px;
}

.description-textarea {
  height: 5rem;
  width: 100%;
  resize: none;
}

.modal-heading-description {
  color: #282860;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.28px;
  opacity: 0.7;
}

.modal-content-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  height: 100%;
}

.right-panel {
  flex: 5;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 30px;
}

.left-panel {
  flex: 7;
  height: 100%;
}

.report-format-selector-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

.report-format-selector-container > p {
  color: #282860;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.7;
}

.notes-content-container {
  font-size: 14px;
  font-weight: 500;
  overflow-y: auto;
}

.collapsible {
  transition: height 1s;
  contain: strict;
  overflow-y: scroll;
}

.expanded {
  height: 250px;
}

.collapsed {
  height: 0px;
}

.save-button {
  border-radius: 8px;
  background: #5f6ce1;
  color: #fff;
  padding: 10px 25px;
  cursor: pointer;
  user-select: none;

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.save-button-inactive {
  border-radius: 8px;
  background: #5f6ce1;
  opacity: 0.4;
  color: #fff;
  padding: 10px 25px;
  cursor: not-allowed;
  pointer-events: none;
  user-select: none;

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.save-button:hover {
  background: #4d58bb;
}
