.auth-page-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
}

.auth-page_column {
  flex: 50%;
  height: 100%;
  /* padding: 5rem 2.5rem; */
}

.auth-page_right {
  background-color: #f0f3f7;
  display: none;
}

.auth-page-img-wrapper {
  height: calc(100%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-page-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.login-form-wrapper {
  width: 100%;
  height: calc(100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 3rem;
}

.login-form-logo {
  position: absolute;
  top: 0;
  left: 0;
  height: 3rem;
  object-fit: contain;
  object-position: center;
}

/**************************  Auth AWS Form styling **********************/
.amplify-tabs {
  display: none !important;
}

:root {
  /* --amplify-font-sizes-large: 1.25rem;
    --amplify-font-sizes-medium: 1.5rem !important;
    --amplify-font-sizes-small: 1.25rem !important; */

  --amplify-colors-background-primary: transparent;
  /* --amplify-colors-background-secondary: #f5f5f5; */
  --amplify-components-authenticator-router-background-color: unset !important;

  --amplify-colors-brand-primary-100: #505fe6 !important;
  --amplify-colors-brand-primary-90: #5563dc !important;
  --amplify-colors-brand-primary-80: #6b76da !important;
  --amplify-colors-brand-primary-20: white !important;
  --amplify-colors-brand-primary-10: white !important;
}

[data-amplify-authenticator] [data-amplify-router] {
  box-shadow: none !important;
  border: none !important;
}

.amplify-phonenumberfield {
  display: none !important;
}

@media screen and (min-width: 1024px) {
  .auth-from {
    /* width: 50%; */
  }
  /* [data-amplify-authenticator] {
      width: 100% !important;
    } */

  /* [data-amplify-authenticator] [data-amplify-container] {
      margin-top: -100px !important;
      width: 30% !important;
    } */

  .amplify-input {
    /* font-style: normal; */
    /* font-weight: 400; */
    /* height: 48px !important; */
    background: rgba(221, 226, 233, 0.15);
    border: 1px solid #dde2e9;
    border-radius: 0.5rem;

    /* border: none !important; */
  }

  .amplify-input.amplify-input--error {
    border: 1px solid rgba(241, 94, 43, 1);
  }

  .amplify-field-group__outer-end .amplify-field-group__control:not(:focus),
  .amplify-field-group__outer-end .amplify-field-group__control:focus {
    background-color: rgba(255, 255, 255, 0.3);
    /* border: none !important; */
    /* height: 48px !important; */
    background: rgba(221, 226, 233, 0.15);
    border: 1px solid #dde2e9;
    border-radius: 0 0.5rem 0.5rem 0;
  }

  .amplify-button[data-variation="primary"] {
    font-style: normal;
    /* font-weight: 600 !important; */
    color: rgb(255, 255, 255) !important;
    border-radius: 1rem !important;
    /* height: 55px !important; */
  }

  .amplify-button[data-variation="link"] {
    color: rgba(241, 94, 43, 1) !important;
    box-shadow: none !important;
  }

  .amplify-text--error {
    color: rgba(241, 94, 43, 1);
  }

  h3.amplify-heading {
    font-weight: 700;
    font-size: var(--amplify-components-heading-3-font-size);
  }
  .auth-page_right {
    background-color: #f0f3f7;
    display: block;
  }
}

/*******************************************************************/
