.realtime-session-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* height: 100%; */
}

.realtime-session-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.realtime-session-audio {
  display: flex;
  flex-direction: row;
  border-radius: 1.5rem;
  border: 1px solid rgba(40, 40, 96, 0.15);
  background: #fff;
  padding: 1.5rem;
}

.realtime-session-audio-play-pause {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 6rem;

  /* padding: 1rem; */
}

.realtime-session-audio-wavesurfer-wrapper {
  width: calc(100% - 6rem);
}

.realtime-session-client-sentiment-show-button {
  cursor: pointer;
}

.realtime-session-client-sentiment-show-button span {
  color: #5f6ce1;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: 0.32px;
}

.realtime-session-client-sentiment-score {
  /* height: 20rem; */
  max-height: 20rem;
  display: flex;
}

.realtime-session-client-sentiment-show-button {
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
}

.realtime-session-client-sentiment-show-button > div {
  display: flex;
  gap: 1rem;
}

.realtime-session-progress-notes-transcript-wrapper {
  border-radius: 1.5rem;
  border: 1px solid rgba(40, 40, 96, 0.15);
  background: #fff;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow-y: overlay;
}
.realtime-session-progress-notes-transcript-header {
  border-bottom: 1px solid rgba(40, 40, 96, 0.15);
  display: flex;
  gap: 3rem;
  padding: 1.5rem 1.5rem 0;
}

.realtime-session-progress-notes-transcript-header > .sub-heading {
  align-items: center;
  color: #282860;
  cursor: pointer;
  display: block;
  font-family: Roboto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  opacity: 1;
  padding: 0 0.5rem 0.5rem;
  text-align: center;
  opacity: 0.5;
}

.realtime-session-progress-notes-transcript-header > .sub-heading-selected {
  opacity: 1;
  border-bottom: 2px solid #282860;
}

.realtime-session-progress-notes-transcript-body {
  flex: 1 1 auto;
  overflow-y: overlay;
}
.realtime-session-progress-notes-wrapper {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow-y: overlay;
}

.realtime-session-progress-notes-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.realtime-session-progress-notes-heading {
  color: #282860;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: 0.28px;
}

.realtime-session-progress-notes-body {
  border-radius: 1rem;
  border: 1px solid rgba(40, 40, 96, 0.15);
  background: #fff;
  padding: 1.5rem;
  color: #282860;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: 0.28px;
}
