.daily-calendar-container {
  margin-top: 1rem;
  overflow-y: overlay;
  flex: 1 1 auto;
  scrollbar-gutter: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.daily-calendar-current-date {
  font-weight: 500;
  font-size: 1rem;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;

  opacity: 0.7;
}

.daily-calendar-day-group {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.daily-calendar-event {
}

.daily-calendar-event-details {
  padding: 1.25rem;
  position: relative;
  border: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 0.75rem 0.75rem 0px 0px;
  border-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.daily-calendar-event-action {
  text-align: center;
  padding: 1rem;
  background: #f4f5fe;
  border: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 0px 0px 0.75rem 0.75rem;
  cursor: pointer;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;

  color: #5f6ce1;
}

.daily-calendar-session-client {
  margin-left: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.daily-calendar-session-client-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  /* identical to box height, or 138% */

  display: flex;
  align-items: center;

  color: #282860;

  opacity: 0.7;
}

.daily-calendar-session-client-number {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;

  display: flex;
  align-items: center;

  color: #282860;

  opacity: 0.7;
  margin-top: 4px;
}

.daily-calendar-session-status {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  font-size: 0.875rem;

  display: flex;
  align-items: center;

  color: #282860;

  opacity: 0.7;
}

.daily-calendar-session-time {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;

  display: flex;
  align-items: center;

  color: #282860;

  opacity: 0.7;
}

.daily-calendar-event-session-client-img {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  object-fit: contain;
  object-position: center;
}

.daily-calendar-session-time-missed {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;

  display: flex;
  align-items: center;

  color: #e97899;

  opacity: 0.7;
}

.daily-caledar-day-group {
  margin-bottom: 40px;
}

.daily-calendar-no-sessions-infotext {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  font-size: 1rem;

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;

  opacity: 0.5;
  margin-top: 24px;
}

.daily-calendar-session-time-group img {
  width: 1.25rem;
  object-fit: contain;
  object-position: center;
  margin-right: 0.5rem;
}

.text-decoration-none {
  text-decoration: none;
}

.add-session-btn {
  align-items: center;
  background: #5f6ce1;
  border-radius: 0.5rem;
  color: #ffffff;
  cursor: pointer;
  display: flex;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  /* identical to box height */

  padding: 0.5rem 1.5rem;
  text-align: center;
}

.add-session-btn:disabled {
  cursor:not-allowed;
  background: #9097cc;
}

.plus-icon {
  height: 1rem;
  margin-right: 0.75rem;
}

.copy-icon {
  height: 1rem;
}

.daily-calendar-event-delete {
  top: 0.5rem;
  right: 0.5rem;
  position: absolute;
}

.daily-calendar-event-delete img {
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
}

.session-description {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.session-description-heading {
  color: #282860;
  font-family: Roboto;
  font-size: 0.875rem;
  opacity: 0.7;
  font-weight: 600;
}

.session-description-body {
  color: #282860;
  font-family: Roboto;
  font-size: 0.875rem;
  opacity: 0.7;
  font-weight: 500;
  text-align: justify;
}
