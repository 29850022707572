.assessment-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.assessment-data-container {
  width: 100%;
}

.assessment-data-scroll-container {
  width: 100%;
}
