.notes-container {
  /* border: 1px solid rgba(40, 40, 96, 0.2);
  border-radius: 16px; */
  display: flex;
}
.notes-loader {
  position: absolute;
  top: 50%;
  right: 45%;
  z-index: 1;
}

.notes-side-vertical-bar {
  width: 100%;
  border-radius: 16px 0px 0px 16px;
  padding: 1rem;
  height: calc(100%);
  display: flex;
  flex-direction: column;
}

.notes-side-vertical-bar-heading {
  display: flex;
  justify-content: space-between;

  margin-top: 1rem;
  margin-bottom: 1rem;
}

.notes-tags-selector {
  display: flex;
  align-items: center;
}
.notes-tags-selector-heading {
  margin-right: 5px;
  font-weight: 600;
}
.new-therapy-note-button {
  width: 150px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: #f1d07c;
  color: #282860;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.new-therapy-note-button {
  background-color: #d5b76c;
}

.note-tag {
  display: inline-block;
  text-align: center;
  border-radius: 0.375rem;
  font-size: 12px;
  margin: 3px;
  padding: 5px;

  color: #282860;
  border: 1px solid #5f5fc3;
  background: rgba(171, 178, 242, 0.5);
  font-weight: 500;
}

.current-tag {
  color: #fff;
  border: 1px solid #5f6ce1;
  background: #5f6ce1;
}

.note-tag:hover {
  cursor: pointer;
}

.note-view-tag {
  display: flex;
  align-items: center;
  color: rgba(40, 40, 96, 0.5);
  font-size: 12px;
}

.note-view-tag-image {
  margin-right: 5px;
  width: 15px;
}

.note-view-tag-text {
  margin-top: 3px;
}

.all-notes-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  gap: 1.5rem;
  overflow-y: overlay;
  margin-top: 15px;
  /* justify-content: center; */
  /* align-items: center; */
  position: relative;
}

.note-view-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.note-view-date {
  color: #282860;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.125rem */
  letter-spacing: 0.015rem;
}

.note-view-text {
  width: 100%;
  background: rgba(221, 226, 233, 0.7);
  padding: 1rem 1.75rem;
  margin-top: 0.2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #282860;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.time-view {
  color: #282860;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 0px;
  line-height: 14px;
}

.note-view-container.current-open .note-view-text {
  cursor: not-allowed;
  background: #abb2f2;
  color: #282860;
}

.editor-container {
  position: relative;
  width: 100%;
}

.editor-container-header {
  display: flex;
  margin-bottom: 20px;
}

.editor-container-header-back-button {
  margin-right: 10px;
  background-color: #abb2f2;
  font-size: 25px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.editor-container-header-heading {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 2%;
  color: #282860;
}

.ai-notes-container {
  position: relative;
  width: 100%;
}

.report-container {
}

.report-container-fixed {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "header header"
    "report-selector save-button";
  margin-bottom: 20px;
}

.report-container-scrollable {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "report your-notes"
    "report transcript-view"
    "report suggestion-panel";
  grid-column-gap: 50px;
}

.ai-generated-report-header {
  grid-area: header;
  color: rgba(40, 40, 96, 1);
  font-family: Poppins;
  letter-spacing: 0.02em;
  text-align: left;
}
.ai-generated-report-header-heading {
  font-size: 18px;
  font-weight: 600;
}
.ai-generated-report-header-subheading {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.8;
}

.ai-generated-report-selector {
  grid-area: report-selector;
  font-family: Poppins;
  font-size: 15px;
  color: rgba(40, 40, 96, 1);
}
.ai-generated-report-selector p {
  margin-bottom: 0px;
  font-weight: 500;
}

.ai-generated-report-save-button {
  grid-area: save-button;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ai-generated-report-save-button div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5f6ce1;
  color: #fff;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
  width: 150px;
  height: 50px;
  border-radius: 10px;
}

.ai-generated-report {
  grid-area: report;
  font-family: "Poppins";
  color: rgba(40, 40, 96, 1);
}
.ai-generated-report-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  text-align: left;
  letter-spacing: 0.02em;
  color: rgba(40, 40, 96, 1);
  font-family: "Poppins";
}

.ai-generated-report-section-heading {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}
.ai-generated-report-section-content {
  line-height: 24px;
  font-size: 14px;
  height: 150px !important;
  width: 100%;
  resize: none;
  border-radius: 5px;
  border: 1px solid rgba(40, 40, 96, 0.2);
  padding: 5px;
  cursor: not-allowed;
}

.ai-generated-report-suggestion-panel {
  grid-area: suggestion-panel;
  text-align: left;
  letter-spacing: 0.02em;
  color: rgba(40, 40, 96, 1);
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
}

.ai-generated-report-suggestion-panel-heading {
  font-size: 15px;
  font-weight: 600;
}

.ai-generated-report-suggestion-panel-subheading {
  opacity: 0.8;
  font-weight: 600;
  margin-bottom: 5px;
}

.ai-generated-report-transcript-view {
  margin-bottom: 20px;
  height: fit-content;
}

.ai-generated-report-your-notes {
  grid-area: your-notes;
  text-align: left;
  letter-spacing: 0.02em;
  color: rgba(40, 40, 96, 1);
  font-family: "Poppins";
  margin-bottom: 15px;
  height: fit-content;
}

.transcript-container {
  box-shadow: 0px 8px 16px 0px #29282d05;
  border: 1px solid #28286026;
  background: #29282d0a;
  border-radius: 8px;
  padding: 0px 12px;
}

.collapsible {
  transition: height 1s;
  contain: strict;
  overflow-y: scroll;
}

.expanded {
  height: 250px;
}

.collapsed {
  height: 0px;
}
