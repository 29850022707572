.view-live-transcription {
  color: #5f6ce1;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: 0.28px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  cursor: pointer;
  user-select: none;
}

.view-transcript-button {
  border-radius: 5px;
  background: rgba(171, 178, 242, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 5px 10px;
}

.button {
  border-radius: 6px;
  background: #f1d07c;
  padding: 6px 10px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;

  cursor: pointer;
  user-select: none;
}

.play-button {
  background: #5F6CE1;
  color: #FFF;
}

.play-button:hover {
  background: #4653c9;
}

.pause-button {
  background: #f1d07c;
  color: #33333d;
}

.pause-button:hover {
  background: #e2b952;
}