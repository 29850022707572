.assessment-score-container {
  font-family: "Poppins";
}

.assessment-score-container-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.assessment-score-container-selector > div:first-child {
  color: rgba(40, 40, 96, 1);
  font-weight: 500;
}

.assessment-score-graph {
  height: 200px;
}

.assessment-item-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100px;
}

.assessment-item-wrapper > div {
  border-right: 2px solid rgba(40, 40, 96, 0.3);
  cursor: pointer;
}

.assessment-item-wrapper > div:last-child {
  border-right: none;
}

.assessment-item-date-time-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* flex-grow: 1; */
  width: 200px;
}

.assessment-item-date {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  color: rgba(40, 40, 96, 1);
}

.assessment-item-time {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  color: rgba(40, 40, 96, 0.8);
}

.assessment-item-score-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-grow: 1; */
  width: 120px;
}

.assessment-item-score {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0.02em;
}

.assessment-item-maximum-score {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.02em;
}

.assessment-item-summary-wrapper {
  flex-grow: 3;
}

.assessment-item-edit-icon-wrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  /* flex-grow: 1; */
  width: 80px;
}
