.add-assessment {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #28286033;
  opacity: 0.5px;
  width: 100%;
  padding: 20px;
  font-family: "Poppins";
  margin-bottom: 40px;
  color: rgba(40, 40, 96, 1);
  font-weight: 500;
}

.add-assessment-button-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}

.add-assessment-frequency-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.assessment-frequency-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #28286033;
  padding-bottom: 10px;
  padding-top: 10px;
  color: rgba(40, 40, 96, 0.5);
}
.assessment-frequency-list-item-assessment-type {
  color: rgba(40, 40, 96, 1);
}

.assessment-frequency-list-item:last-child {
  border: none;
}

.assessment-frequency-list-item-frequency {
  display: flex;
  align-items: center;
}

.assessment-frequency-list-item-frequency > div:first-child {
  margin-right: 5px;
  font-weight: 600;
}

.assessment-frequency-list-item-last-taken {
  display: flex;
  align-items: center;
}

.assessment-frequency-list-item-last-taken > div:first-child {
  margin-right: 5px;
  font-weight: 600;
}

.assessment-frequency-list-item-due-date {
  display: flex;
  align-items: center;
}

.assessment-frequency-list-item-due-date > div:first-child {
  margin-right: 5px;
  font-weight: 600;
}
