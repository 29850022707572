.assessment-detail-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.assessment-detail-header {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.assessment-detail-header-back-button {
  margin-right: 10px;
  background-color: #abb2f2;
  font-size: 25px;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.assessment-detail-header-date-time {
  display: flex;
  flex-direction: column;
  justify-self: center;
}

.assessment-detail-header-date {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(40, 40, 96, 1);
}

.assessment-detail-header-time {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-align: left;

  color: rgba(40, 40, 96, 0.7);
}

.assessment-detail-answer-list {
  width: 100%;
}

.assessment-detail-answer-list-item {
  border: 1px solid rgba(40, 40, 96, 0.3);
  border-radius: 16px;
  height: 80px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: rgba(51, 51, 51, 1);
}

.assessment-detail-answer-list-item-number {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  width: 5%;
}
.assessment-detail-answer-list-item-number > span {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Poppins;
  font-size: 14px;
  color: rgba(40, 40, 96, 1);
}

.assessment-detail-answer-list-item-question {
  width: 65%;
  font-family: Poppins;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  font-weight: 500;
}

.assessment-detail-answer-list-item-option {
  width: 20%;
  margin-left: 10px;
}

.assessment-detail-answer-list-item-option > div {
  border: 1px solid rgba(40, 40, 96, 0.5);
  border-radius: 8px;
  padding: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-family: Poppins;
  font-size: 14px;

  background-color: rgba(255, 255, 255, 0.5);
}

.assessment-detail-answer-list-item-score {
  width: 10%;
  margin-left: 10px;
}

.assessment-detail-answer-list-item-score > div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
}
