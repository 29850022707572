.add-client-btn {
  flex-grow: 0.2;
  background: #5f6ce1;
  border-radius: 8px;
  border: none;
  padding: 8px 16px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: inline-block;
  align-items: center;
  text-align: center;

  color: #ffffff;
  cursor: pointer;
}

.invite-link-container {
  flex-grow: 1;
  height: 40px;
  background: rgba(95, 108, 225, 0.15);
  border: 1px solid #dde2e9;
  border-radius: 8px;
  padding: 10px 22px;
  margin-bottom: 40px;
  margin-right: 24px;
}

.invite-link {
  height: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0125em;
  color: #282860;
  opacity: 0.5;
}

.copy-link-btn {
  display: inline-block;
  padding: 10px 8px;
  cursor: pointer;
  width: 40px;
  height: 40px;

  background: #ffffff;
  border: 1px solid rgba(95, 108, 225, 0.5);
  border-radius: 4px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  color: #5f6ce1;
}

.copy-link-btn-active {
  background: #5f6ce1;
}

.add-client-manually-link {
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  /* identical to box height */

  color: #5f6ce1;
}

.manual-client-invite-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */

  display: flex;
  align-items: center;

  letter-spacing: 0.02em;
  margin-top: 8px;

  color: #282860;

  opacity: 0.7;
}

.close-modal-btn {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.invite-sent-confirmation {
  background: rgba(51, 197, 92, 0.1);
  border: 1px solid #33c55c;
  border-radius: 8px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  letter-spacing: 0.0125em;

  color: #33c55c;
  padding: 8px 22px;
  height: 40px;
}

.invite-sent-failed {
  background: rgba(197, 51, 51, 0.1);
  border: 1px solid #dd2424;
  border-radius: 8px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  letter-spacing: 0.0125em;

  color: #dd2424;
  padding: 8px 22px;
  height: 40px;
}

.btn-disabled {
  background-color: #d1d5db; /* Light gray background */
  color: #6b7280; /* Gray text */
  cursor: not-allowed; /* Show 'not-allowed' cursor */
  opacity: 0.7; /* Reduce opacity */
  pointer-events: none; /* Prevent interactions */
}
