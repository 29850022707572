.assessment-summary {
  display: flex;
  flex-direction: row;
  height: 80px;
  border: 1px solid #28286033;
  border-radius: 16px;
  opacity: 0.5px;
  width: 100%;
  padding: 10px;
  justify-content: start;
  align-items: center;
  font-family: "Poppins";
  margin-bottom: 40px;
}

.assessment-summary-heading {
  font-size: 16px;
  font-weight: 500;
  margin-right: 20px;
  color: #282860;
}

.assessment-summary-text {
  font-size: 14px;
  font-weight: 500;
  color: #282860;
}

.assessment-summary-vertical-line {
  height: 50px;
  border-left: 1px solid #dde2e9;
  margin-right: 20px;
}

.assessment-summary-completed {
  font-size: 16px;
  font-weight: 600;
  margin-right: 20px;
  color: #5f6ce1;
}

.assessment-summary-phq9 {
  font-size: 18px;
  font-weight: 600;
  margin-right: 20px;
  color: #5f6ce1;
}

.assessment-summary-gad7 {
  font-size: 18px;
  font-weight: 600;
  margin-right: 40px;
  color: #5f6ce1;
}

.assessment-summary-ai-check-in {
  font-size: 18px;
  font-weight: 600;
  margin-right: 40px;
  color: #5f6ce1;
}

.assessment-summary-missed {
  font-size: 18px;
  font-weight: 600;
  margin-right: 20px;
  color: #ec7440;
}
