.auth-page-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
}

.auth-page_column {
  flex: 50%;
  height: 100%;
  /* padding: 5rem 2.5rem; */
}

.auth-page_right {
  background-color: #f0f3f7;
}

.auth-page-img-wrapper {
  height: calc(100%);
  width: 100%;
  display: flex;
  justify-content: cover;
  align-items: center;
}

.auth-page-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.login-form-wrapper {
  width: 100%;
  height: calc(100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 50px;
}

.login-form-logo {
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  object-fit: contain;
  object-position: center;
}

.unverified-upload-wrapper > span {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}
